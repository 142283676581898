<template>
  <div class="login">
    
    <div class="content">
      <div class="title"><img height="40" src="../assets/img/Index_JobSeeker/logo.png" alt=""></div>
      <div class="from">
        <h3>注册</h3>
        <p class="tag">已有账号？<a href="/login">去登录</a></p>
        <div class="tab-catalog-00">
          <a :class="loginType==1?'atthis':''" @click="tabType(1)" href="javascript:void(0)">我要找工作</a>
          <a :class="loginType==2?'atthis':''" @click="tabType(2)" href="javascript:void(0)">我要招人</a>
        </div>
        <transition-group name="el-zoom-in-center" tag="div">
        <div class="bd" v-show="loginType==1" key="job">
            <div class="item"><input type="text" maxlength="11" class="phone" placeholder="请输入手机号" v-model="job.tel"></div>
            <div class="item code"><input type="text" placeholder="请输入图形验证码" v-model="job.captcha"> <img @click="getcode" :src="codeImg.image?'data:image/png;base64,'+codeImg.image:''" alt="" srcset=""></div>
            <div class="item"><input type="text" class="code" placeholder="请输入验证码" v-model="job.shortCaptcha">
              <div class="yzm" v-show="shortCode" @click="shortCaptcha">获取验证码</div>
              <div class="yzm send" v-show="!shortCode" >{{count}}s后重新获取</div>
            </div>
            <div class="item"><input type="password" class="password" placeholder="请输入登录密码" v-model="job.password"></div>
        </div>
        <div class="bd" v-show="loginType==2" key="recruit">
            <div class="item"><input type="text" class="license" placeholder="请输入公司营业执照" v-model="recruit.businessLicense"></div>
            <div class="item"><input type="text" maxlength="11" class="phone" placeholder="请输入手机号" v-model="recruit.tel"></div>
            <div class="item code"><input type="text" placeholder="请输入图形验证码" v-model="recruit.captcha"> <img @click="getcode" :src="codeImg.image?'data:image/png;base64,'+codeImg.image:''" alt="" srcset=""></div>
            <div class="item"><input type="text" class="code" placeholder="请输入验证码" v-model="recruit.shortCaptcha">
              <div class="yzm" v-show="shortCode" @click="shortCaptcha">获取验证码</div>
              <div class="yzm send" v-show="!shortCode" >{{count}}s后重新获取</div>
            </div>
            <div class="item"><input type="text" class="name" placeholder="请输入登录账号" v-model="recruit.account"></div>
            <div class="item"><input type="password" class="password" placeholder="请输入登录密码" v-model="recruit.password"></div>
        </div>
        </transition-group>
        <div class="ts"><span>*</span>密码至少6位，包含字母和数字</div>
        <div class="btn" @click="register">
          <img src="../assets/img/login/btn-reg.png" alt="" srcset="">
        </div>
        <div class="fwxy">
          <el-tooltip effect="dark" content="请勾选《用户服务协议》、《隐私协议》" placement="top">
            <span class="box" :class="checked?'atthis':''" @click="checked=!checked"></span>
          </el-tooltip>
         进入即代表你已同意<a href="javascript:void(0);" @click="dialogTableVisible=true">《用户服务协议》</a>以及<a href="javascript:void(0);" @click="dialogTableVisible1=true">《隐私协议》</a>
        </div>
      </div>
    </div>
    <el-dialog class="xytk" title="用户服务协议" :visible.sync="dialogTableVisible">
      <div class="xy" v-if="userProtocol" v-html="unEscapeHTML(userProtocol.content)"></div>
      <div v-else style="text-align: center; padding-top: 50px;font-size: 14px; color: #999">暂无数据</div>
    </el-dialog>

    <el-dialog class="xytk" title="隐私协议" :visible.sync="dialogTableVisible1">
      <div class="xy" v-if="userProtocol1" v-html="unEscapeHTML(userProtocol1.content)"></div>
      <div v-else style="text-align: center; padding-top: 50px; font-size: 14px; color: #999">暂无数据</div>
    </el-dialog>
  </div>
</template>
<script>
import { captcha, register, shortCaptcha, protocol } from '@/api/login'
export default {
  name: "Register",
  data(){
    return {
      codeImg: {},
      loginType: 1,
      checked: false,
      count: 60,
      shortCode: true,
      userProtocol: '',
      userProtocol1: '',
      dialogTableVisible: false,
      dialogTableVisible1: false,
      job: {
        tel: '',
        shortCaptcha: '',
        captcha: '',
        password: ''
      },
      recruit: {
        businessLicense: '',
        tel: '',
        shortCaptcha: '',
        account: '',
        password: '',
        captcha: ''
      }
    }
  },
  components: {
  },
  created() {
    this.getcode()
    // 用户协议
    protocol(1).then(res => {
      this.userProtocol = res.data
    })
    // 隐私
    protocol(2).then(res => {
      this.userProtocol1 = res.data
    })
  },
  methods:{
    unEscapeHTML(html) {
        html= "" +html;
        return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'").replace(/<[^>]+>/g,"").replace(/&nbsp;/g,'');
    },
    // 短信验证码
    shortCaptcha(){
      this.shortCode = false
      let params = {}
      if(this.loginType == 1){
        if(!this.job.tel){
          this.$message.warning('手机不能为空！')
          this.shortCode = true;
          return
        }
        if(this.codeImg.code !== this.job.captcha){
          this.$message.warning('图形验证码输入错误请重新输入！')
          this.getcode()
          this.shortCode = true
          return
        }
        params = {
          tel: this.job.tel
        }
      } else {
        if(!this.recruit.tel){
          this.$message.warning('手机不能为空！')
          this.shortCode = true;
          this.shortCode = true
          return
        }
        if(this.codeImg.code !== this.recruit.captcha){
          this.$message.warning('图形验证码输入错误请重新输入！')
          this.getcode()
          this.shortCode = true
          return
        }
        params = {
          tel: this.recruit.tel
        }
      }
      shortCaptcha(params).then(res => {
        console.log(res)
        this.$message.success('短信发送成功')
        this.count = 60;
        this.shortCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
              this.count--;
          } else {
              this.shortCode = true;
              clearInterval(this.timer);
              this.timer = null;
          }
      }, 1000)
      }).catch(err => {
        console.log(err)
        this.shortCode = true;
      })
    },
    // 图形验证码
    getcode() {
      captcha().then(res => {
        this.codeImg = res.data
      })
    },
    // 注册
    register() {
      let params = {}
      if(this.loginType == 1){
        if(!this.job.tel){
          this.$message.warning('手机号不能为空！')
          return
        }
        if(!this.job.captcha){
          this.$message.warning('图形验证码不能为空！')
          return
        }
        if(!this.job.shortCaptcha){
          this.$message.warning('短信验证码不能为空！')
          return
        }
        if(!this.job.password){
          this.$message.warning('密码不能为空！')
          return
        }
        if(this.job.password.length<6 || (!/\d/.test(this.job.password) || !/[a-z|A-Z]/.test(this.job.password))){
          this.$message.warning('密码至少6位，必须包含字母和数字！')
          return
        }
        params = {
          ...this.job,
          loginType: this.loginType,
          uuid: this.codeImg.imgId
        }
      } else {
        if(!this.recruit.businessLicense){
          this.$message.warning('营业执照不能为空！')
          return
        }
        if(!this.recruit.tel){
          this.$message.warning('手机号不能为空！')
          return
        }
        if(!this.recruit.captcha){
          this.$message.warning('图形验证码不能为空！')
          return
        }
        if(!this.recruit.shortCaptcha){
          this.$message.warning('短信验证码不能为空！')
          return
        }
        if(!this.recruit.account){
          this.$message.warning('账号不能为空！')
          return
        }
        if(!this.recruit.password){
          this.$message.warning('密码不能为空！')
          return
        }
        if(this.recruit.password.length<6 || (!/\d/.test(this.recruit.password) || !/[a-z|A-Z]/.test(this.recruit.password))){
          this.$message.warning('密码至少6位，必须包含字母和数字！')
          return
        }
        params = {
          ...this.recruit,
          loginType: this.loginType,
          uuid: this.codeImg.imgId
        }
      }
      if(!this.checked) {
        this.$message.warning('请勾选用户服务协议！')
        return
      }
      register({...params, loginPlatform: 1, source: 1}).then(res => {
        console.log(res)
        this.$message.success('注册成功')
        this.$router.push('/login')
      })
    },
    tabType(str) {
      this.loginType = str
    }
  }
};
</script>
<style scoped lang="scss">
.login{
  height: 100vh;
  background: transparent url('../assets/img/login/bg.png') no-repeat left top;
  background-size: 100% 100vh;
  .title{
    margin-bottom: 20px;
  }
  .content{
    width: 500px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: left;
    .from{
      text-align: center;
      background: #fff;
      padding: 20px 60px 10px;
      border-radius: 4px 4px 4px 4px;
    }
    .ts{
      color: #666666;
      margin-bottom: 23px;
      margin-top: -10px;
      text-align: left;
      span{
        color: #FF2647;
      }
    }
    .tag{
      color: #999999;
      margin: -16px 0 32px;
      a{
        color: #0780E6;
      }
    }
    h3{
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 32px;
    }
    .bd{
      margin-top: 32px;
      &.el-zoom-in-center-leave-active{
        display: none;
      }
    }
    .tips{
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      text-align: left;
      text-decoration: underline;
      margin-bottom: 20px;
      cursor: pointer;
      margin-top: -3px;
      p{
        float: right;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .item{
      margin-bottom: 16px;
      position: relative;
      img{
        width: 115px;
        height: 50px;
      }
      input{
        height: 50px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #C4C7D3;
        line-height: 50px;
        width: 100%;
        padding: 0 8px;
        &.name{
          background: transparent url('../assets/img/login/icon-user.png') no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
        &.phone{
          background: transparent url('../assets/img/login/icon-phone.png') no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
        &.password{
          background: transparent url('../assets/img/login/icon-password.png') no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
        &.code{
          background: transparent url('../assets/img/login/icon-yzm.png') no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
        &.license{
          background: transparent url('../assets/img/login/icon-name.png') no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
        
      }
      .yzm{
        position: absolute;
        right: 0;
        font-size: 14px;
        color: #0780E6;
        top: 0;
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 110px;
        cursor: pointer;
        &::after{
          content: '';
          position: absolute;
          left: 0;
          height: 24px;
          width: 1px;
          background: #eee;
          top: 13px;
        }
        &.send{
          color: #999;
        }
      }
      &.code{
        input{
          width: 265px;
          border-radius: 4px 0 0 4px;
        }
        img{
          float: right;
        }
      }
    }
    .btn{
      img{
        width: 380px;
        height: 80px;
        cursor: pointer;
        &:hover{
          opacity: .8;
        }
      }
    }
  }
}
.tab-catalog-00{
  font-size: 18px;
  font-weight: normal;
  border-bottom: 1px solid #CCCCCC;
  margin: 0 56px;
  overflow: hidden;
  a{
    color: #0780E6;
    padding-bottom: 10px;
    float: left;
    width: 50%;
    text-align: left;
  }
  a:last-child{
    float: right;
    text-align: right;
  }
  a.atthis{
    border-bottom: 3px solid #3580DF;
    margin-bottom: -1px;
  }
}
.fwxy{
  margin-bottom: 10px;
  a{
    color: #0780E6;
  }
  .box{
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 2px 2px 2px 2px;
    cursor: pointer;
    position: relative;
    top: 4px;
    &.atthis{
      background: transparent url('../assets/img/login/box.png') left top;
      background-size: 16px 16px;
      border: 0;
    }
  }
}
.xy{
  max-height: 70vh;
  overflow: auto;
}
.xytk /deep/ .el-dialog__body{
  padding-top: 5px;
}
</style>
